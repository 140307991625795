.fade-enter {
  opacity: 0;
  transform: translateY(-15px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0px);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}
